<template>
    <div>
        <modal-whatsapp :id-appointment="idAppointmentSelect" v-if="showModalWA"></modal-whatsapp>
        <modal-reservasi v-if="showModalReservasi" :caller-url="'call-center'"></modal-reservasi>
        <base-header base-title="Call Center"></base-header>
        <!-- begin:: Content -->
        <load-spinner v-if="loadSpinner"></load-spinner>
        <div
            class="kt-content kt-grid__item kt-grid__item--fluid"
            id="kt_content"
        >
            <div class="kt-portlet kt-portlet--mobile">
                <div class="kt-portlet__head kt-portlet__head--lg">
                    <div class="container-fluid">
                        <div class="row mt-2">
                            <div class=" col-md-10 " id="accordion">
                                <filter-search></filter-search>
                            </div>
                            <div class="  col-md-2 ">
                                <div
                                    class="kt-portlet__head-wrapper grid-notif"
                                >
                                    <sound-notifiction
                                        ref="notif"
                                    ></sound-notifiction>
                                    <auto-refresh
                                        ref="auto"
                                        :on-refresh="fetch"
                                        :auto-refresh-state="false"
                                    ></auto-refresh>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="m-4">
                    <filter-checkbox></filter-checkbox>
                </div>

                <div
                    class="kt-portlet__body tab-content"
                    v-if="
                        !loadingApi &&
                            $store.state.DoctorAppointmentStore
                                .listCallCenterBooking.length > 0
                    "
                >
                    <div class="tab-pane fade active show">
                        <!-- content -->
                        <div class="row">
                            <div
                                class="col-md-8"
                                
                            >
                                <all-card />
                            </div>
                            <div
                            class="col-md-4"
                            >
                                <panel-info></panel-info>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="loadingApi" class="m-auto p-5 d-flex flex-row h-100">
                    <div
                        class="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--primary my-auto"
                    ></div>
                    <h5 class="my-auto pl-5">Loading ...</h5>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BaseHeader from "./../_base/BaseHeader";
import AutoRefresh from "../_general/AutoRefresh";
import FilterSearch from "./_components/FilterSeach.vue";
import FilterCheckbox from "./_components/FilterCheckbox.vue";
import PanelInfo from "./_components/PanelInfo.vue";
import AllCard from "./_components/AllCard.vue";
import SoundNotifiction from "../../components/_general/SoundNotification.vue";
import SockJS from "sockjs-client";
import Stomp from "webstomp-client";
import { fromEventPattern } from "rxjs";
import { debounceTime } from "rxjs/operators";

// import fileJson from './_components/dataReservasi.json'
import { mapActions, mapState, mapMutations } from "vuex";
import CheckPermission from "./../../services/checkPermission.service";
const checkPermission = new CheckPermission();
import LoadSpinner from "./../_general/LoadSpinner";
export default {
    data(){
        return{
            jumlahEvent: 0
        }
    },
    components: {
        BaseHeader,
        AutoRefresh,
        FilterSearch,
        FilterCheckbox,
        PanelInfo,
        AllCard,
        SoundNotifiction,
        LoadSpinner,
        ModalWhatsapp: () => ({
            component: new Promise(function(resolve, reject) {
                setTimeout(() => {
                    resolve(
                        import("./../doctor-appointments/DayCalendar/_components/ModalWhatsapp.vue")
                    );
                }, 500);
            })
        }),
         ModalReservasi: () => ({
            component: new Promise(function(resolve, reject) {
                setTimeout(function() {
                    resolve(
                        import(/* webpackChunkName: "modal-reservasi-dokter" */ "./../doctor-appointments/ModalReservasi/ModalReservasi.vue")
                    );
                }, 1000);
            }),
            loading: LoadSpinner
        }),
    },
    computed: {
        idAppointmentSelect () {
            return this.$store.state.DayCalendarStore.idReservasi
        },
        ...mapState("DoctorAppointmentStore", {
            loadingApi: state => state.loadingApi,
            stompClient: state => state.stompClient,
            showModalReservasi: state => state.showModalReservasi,
             loadSpinner: state => state.loadSpinner
        }),
        ...mapState('DayCalendarStore', {
            showModalWA: state => state.showModalWA,
        })
    },
    methods: {
        connect() {
            let base = process.env.VUE_APP_WEB_SOCKET;
            let url = `${base}/?access_token=${localStorage.tokens}`;
            var socket = new SockJS(url);

            this.SET_STOMP_CLIENT(Stomp.over(socket));
            // to turn th console log off
            this.stompClient.debug = () => {};
            var e = jQuery.Event("click");
            this.stompClient.connect(
                {},
                frame => {
                    this.stompClient.subscribe("/topic/webbo", tick => {
                        let msg = JSON.parse(tick.body);
                        if (msg.entityType = 'appointment') {
                            // console.log(msg, 'ini pesan');
                            // Create the event
                            var event = new CustomEvent("basket");
                            this.jumlahEvent++
                            document.dispatchEvent(event);
                        }

                    });
                },
                error => {
                    this.disconnect();
                    // console.log("STOMP Closed", error);
                    setTimeout(this.connect(), 2000);
                }
            );
        },
        basketball() {
            let vx = this
            function addClickHandler(handler) {
                document.addEventListener("basket", handler);
            }
            const clicks = fromEventPattern(addClickHandler);
            // debounce with rxjs
            const result = clicks.pipe(debounceTime(500));
            result.subscribe(x => {
                // this.reloadCalendar();
                if (vx.$route.name == 'call-center-appointment') {
                    toastr.clear()
                    toastr.info('Klik Untuk Memperbaharui', `Terdapat ${this.jumlahEvent} Perubahan Data`, 
                    {onclick: function() {
                        vx.fetch()
                    }});
                    $(".toast-title").addClass("text-white");
                    var isActive = window.document.hasFocus();
                    if (!isActive) this.showNotif();
                }
            });
        },
        disconnect() {
            if (this.stompClient && this.stompClient.connected) {
                this.stompClient.disconnect();
            }
        },
         showNotif() {
            // Let's check if the browser supports notifications
            if (!("Notification" in window)) {
                alert("This browser does not support desktop notification");
            }

            // Let's check whether notification permissions have already been granted
            else if (Notification.permission === "granted") {
                // If it's okay let's create a notification
                var notification = new Notification("Booking List");
            }

            // Otherwise, we need to ask the user for permission
            else if (Notification.permission !== "denied") {
                Notification.requestPermission().then(function(permission) {
                    // If the user accepts, let's create a notification
                    if (permission === "granted") {
                        var notification = new Notification(
                            "Booking List"
                        );
                    }
                });
            }

            if (notification !== undefined && notification !== null) {
                notification.onclick = function(event) {
                    window.focus();
                };
            } else {
                toastr.error("please allow notification");
            }
        },
        fetch() {
            this.$store.dispatch("DoctorAppointmentStore/GET_LIST_CALL_CENTER_BOOKING");
            this.jumlahEvent = 0
        },
        clickMe() {
            alert('Clicked');
        },
        notif() {
            this.$refs.notif.ring();
        },
        ...mapMutations({
            SET_STOMP_CLIENT: "DoctorAppointmentStore/SET_STOMP_CLIENT",
            setPermissionWhatsapp: "DoctorAppointmentStore/SET_PERMISSION_WHATSAPP"
        }),
        ...mapActions({
            getCallCenterBooking:
                "DoctorAppointmentStore/GET_CALL_CENTER_BOOKING"
        }),
        checkPermission: function (method) {
            return checkPermission.isCanAccess(method);
        },
    },
    mounted() {
         this.setPermissionWhatsapp(this.checkPermission('Booking.Whatsapp.Send.Active'))
         toastr.options = {
            timeOut: 0,
            extendedTimeOut: 0,
            tapToDismiss: true,
            color: "white"
        };
        
        this.connect();
        this.basketball();
        const obj = {
            "tanggalKunjungan.greaterOrEqualThan": moment().format('YYYY-MM-DD'),
            "tanggalKunjungan.lessOrEqualThan": moment().format('YYYY-MM-DD'),
            "nomorRegistrasi.specified": 'false',
            "status.in": "CONFIRM"
        }
        this.$store.commit("DoctorAppointmentStore/SET_DEFAULT_PARAMS", obj);
        this.$store.commit("DoctorAppointmentStore/SET_SORTING_PARAMS", 'createdDate,desc');
        this.$store.dispatch('DoctorAppointmentStore/GET_LIST_CALL_CENTER_BOOKING')
    },
    destroyed() {
        toastr.clear()
        this.disconnect();
    }
};
</script>