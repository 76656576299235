<template>
    <div class="d-flex mt-3  justify-content-center position-sticky top-0">
        <div
            class="row border shadow-sm  p-3 bg-white rounded ml-2 w-100"
            v-if="!$store.state.DoctorAppointmentStore.selectedPanel.id"
        >
            <div class="col-md-12">
                <div class="row">
                    <div class="col-12">
                        Silahkan pilih Booking List
                    </div>
                </div>
            </div>
        </div>
        <div
            class="row  p-3 border shadow-sm bg-white rounded ml-2"
            v-else-if="$store.state.DoctorAppointmentStore.selectedPanel.id"
        >
            <div class="col-md-12">
                <div class="row">
                    <label class="col-6 kt-font-sm mb-1"
                        >ID #{{ selectedPanel.id }}</label
                    >
                    <label class="col-6 kt-font-sm mb-0 text-right text-danger">
                        <i class="fas fa-times pointer" @click="remove()"></i>
                    </label>
                    <label class="col-6 kt-font-sm mb-0">Spesialisasi</label>

                    <label class="col-12 kt-font-bolder">
                        <!-- {{
                        selectedPanel.jadwal.dokter
                            ? selectedPanel.jadwal.dokter.tujuanRujukan.nama
                            : "-"
                    }} -->
                    {{
                       selectedPanel.spesialisasi
                    }}
                    </label>
                    <hr />
                </div>
                <div class="row">
                    <label class="col-6 kt-font-sm mb-0">dokter</label>
                    <label class="col-12 kt-font-bolder">{{
                        selectedPanel.namaDokter
                    }}</label>
                    <label class="col-12 kt-font-sm mb-0">Jadwal</label>
                    <label class="col-12 kt-font-bolder">{{
                    selectedPanel.start | formatDate
                }}</label>
                    <label class="col-12 kt-font-sm mb-0">Waktu</label>
                    <!-- <label class="col-6 kt-font-sm mb-0">Nomor Antrian</label> -->
                    <label class="col-12 kt-font-bolder">
                        {{ selectedPanel.jamMulai }}
                        -
                        {{ selectedPanel.jamSelesai }}
                    </label>
                    <!-- <label class="col-6 kt-font-bolder"> -- </label> -->
                </div>
                <hr />
                <div class="row">
                    <label class="col-12 kt-font-sm mb-0"
                        >Status: {{ selectedPanel.type }}
                        
                    <template v-if="selectedPanel.status == 'CANCEL'">
                        - {{ selectedPanel.cancelStatus }}
                    </template>
                    </label
                    >
                    <label class="col-6 kt-font-sm mb-0">Nama</label>
                    <label class="col-12 kt-font-bolder">{{
                        selectedPanel.namaPasien
                    }}</label>
                    <label class="col-6 kt-font-sm mb-0">Tanggal Lahir</label>
                    <label class="col-6 kt-font-sm mb-0">No. Rekam Medik</label>
                    <label class="col-6 kt-font-bolder">{{
                        selectedPanel.tanggalLahir
                    }}</label>
                    <label class="col-6 kt-font-bolder">{{
                        selectedPanel.data && selectedPanel.data.nomorMedicalRecord
                            ? selectedPanel.data.nomorMedicalRecord
                            : "-"
                    }}</label>
                    <label class="col-6 kt-font-sm mb-0">Jenis Kelamin</label>
                    <label class="col-6 kt-font-sm mb-0">No. Registrasi</label>
                    <label class="col-6 kt-font-bolder">{{
                        $t(selectedPanel.gender)
                    }}</label>
                    <label
                        class="col-6 kt-font-bolder"
                        v-if="selectedPanel.data.nomorRegistrasi"
                        >{{ selectedPanel.data.nomorRegistrasi }}</label
                    >
                    <label class="col-6 kt-font-bolder" v-else>-</label>
                    <label class="col-6 kt-font-sm mb-0">No. Telepon</label>
                    <template v-if="isPermissionWhatsapp">
                        <label
                            class="col-12 kt-font-bolder show-bigger pointer"
                            @click="WAme(selectedPanel.telp, selectedPanel.generatedId)"
                            >+{{ selectedPanel.telp }} </label
                        >
                    </template>
                    <template v-else>
                        <label class="col-12 kt-font-bolder"
                            >+{{ selectedPanel.telp }}</label
                        >
                    </template>
                    <label class="col-6 kt-font-sm mb-0">Email</label>
                    <label class="col-12 kt-font-bolder">{{
                        selectedPanel.email ? selectedPanel.email : "-"
                    }}</label>
                    <label class="col-6 kt-font-sm mb-0">Pesan</label>
                    <label class="col-12 kt-font-bolder">{{
                        selectedPanel.pesan ? selectedPanel.pesan : "-"
                    }}</label>
                    <label class="col-6 kt-font-sm mb-0">Di Buat Tanggal</label>
                    <label
                        v-b-tooltip.hover.left
                        :title="`Di Buat Oleh: ${selectedPanel.createdBy}`"
                        class="col-12 kt-font-bolder"
                        >{{ selectedPanel.createdDate | formatDateTime }}</label
                    >
                    <label class="col-6 kt-font-sm mb-0">Di Ubah Tanggal</label>
                    <label
                        v-b-tooltip.hover.left
                        :title="`Di Ubah Oleh: ${selectedPanel.data.lastModifiedByName}`"
                        class="col-12 kt-font-bolder"
                        >{{
                            selectedPanel.lastModifiedDate | formatDateTime
                        }}</label
                    >
                    <label class="kt-font-sm mb-0" :class="'col-6'"
                        >Jenis Pembayaran</label
                    >
                    <label
                        class="col-6 kt-font-sm mb-0"
                        v-if="selectedPanel.isTeleconsult"
                        >ID telekonsul</label
                    >
                    <label class="col-6 kt-font-bolder">{{
                        selectedPanel.jenisPenjamin
                            ? selectedPanel.jenisPenjamin
                            : "-"
                    }}</label>
                    <label
                        class="col-6 kt-font-bolder"
                        v-if="selectedPanel.isTeleconsult"
                    >
                        #{{ selectedPanel.teleconsultPayment.id }}
                    </label>
                    <template v-if="selectedPanel.status != 'CANCEL'">
                    <div class="form-group col-12 mb-0">
                        <label for="">No.Rekam Medik</label>
                        <input
                            type="text"
                            class="form-control"
                            v-model="noMedical"
                            :disabled="
                                isDisabled(isBtnLoad, selectedPanel.defect)
                            "
                        />
                    </div>
                    <div class="form-group col-12 mb-2">
                        <label for="">No. Registrasi HIS</label>
                        <input
                            type="text"
                            class="form-control"
                            v-model="noRegis"
                            :disabled="
                                isDisabled(isBtnLoad, selectedPanel.defect)
                            "
                        />
                    </div>
                     </template>
                    <template v-if="selectedPanel.cancelStatus != 'RESCHEDULED' && selectedPanel.cancelStatus != 'DISMISS'">
                    <button
                        class="btn  btn-primary btn-block"
                        @click="updateMrnRegistrasi"
                        v-if="selectedPanel.status != 'CANCEL'"
                        :disabled="isDisabled(isBtnLoad, selectedPanel.defect)"
                    >
                        <div v-if="isBtnLoad" class="spinner-border">
                            <span class="sr-only"></span>
                        </div>
                        Simpan
                    </button>
                    </template>
                    <!-- <p class="col-12 mt-3 mb-0 text-center">Shortcut</p> -->
                     <template v-if="selectedPanel.cancelStatus != 'RESCHEDULED' && selectedPanel.cancelStatus != 'DISMISS' && !selectedPanel.isArrived">
                    <div class="form-group col-6 mt-3 pl-0">
                        <button
                            class="btn btn-primary btn-block"
                            @click.prevent="cancelOrDismiss(selectedPanel)"
                        >
                            Batalkan
                        </button>
                    </div>
                    <div class="form-group col-6 mt-3 pr-0">
                        <button
                            class="btn btn-primary btn-block"
                            @click.prevent="reSchedule()"
                        >
                            Re-Schedule
                        </button>
                    </div>
                     </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { RepositoryFactory } from "./../../../repositories/RepositoryFactory";
const ReservasiResource = RepositoryFactory.get("reservation");
import { mapState, mapMutations } from "vuex";
import SweetAlertTemplate from "./../../_general/SweetAlert";
const SweetAlert = Vue.extend(SweetAlertTemplate);
export default {
    data() {
        return {
            noMedical: "",
            noRegis: "",
            generatedId: ""
        };
    },
    filters: {
        formatDate: function(value) {
            return moment(value).format("dddd, DD MMMM YYYY");
        },
        formatDateTime: function(value) {
            return moment(value).format("DD MMMM YYYY HH:mm");
        },
        // defaultSpesialisasi(array){
        //     let defaultSpesialisasi 
        //     if (array) {
        //         array.forEach(item => {
        //             // console.log(item,'itemmss');
        //             if (item.default) {
        //                 defaultSpesialisasi = item
        //             }
        //         })
        //         console.log(defaultSpesialisasi , "defaultSpesialisasi");
        //         if (defaultSpesialisasi) {
        //             if (defaultSpesialisasi.spesialisasi) {
                        
        //                 return defaultSpesialisasi.spesialisasi.namaIndo
        //             }
        //         }
        //     }
        // }
    },
    methods: {
         ...mapMutations({
            setLoadingSpinner: "DoctorAppointmentStore/SET_LOAD_SPINNER"
        }),
        isDisabled(isLoad, defect) {
            if (defect) return true;
            return isLoad;
        },
        remove() {
            this.$store.commit("DoctorAppointmentStore/SET_SELECTED_PANEL", {});
            const card = document.getElementsByClassName("bg-card");
            for (let i = 0; i < card.length; i++) {
                if (card[i].classList.contains("clickCard"))
                    card[i].classList.remove("clickCard");
            }
        },
        updateMrnRegistrasi () {
            // loading on
            this.isBtnLoad = true;
            var payload = {
                generatedId: this.generatedId,
                nomorRegistrasi: this.noRegis
                    ? this.noRegis.replaceAll(/\s/g, "")
                    : "",
                    mrn: this.noMedical
                    ? this.noMedical.replaceAll(/\s/g, "")
                    : ""
            };
            ReservasiResource.updateNomorRegisDanMedAppointment(
                payload
            )
                .then(response => {
                    toastr.success("Success Update Data");
                    // toast sukses
                    const allData = this.$store.state.DoctorAppointmentStore
                        .listCallCenterBooking;
                    const itemSelected = response.data;
                    var indexChange = allData.findIndex(
                        item => item.id == itemSelected.id
                    );
                    allData[indexChange] = itemSelected;
                    this.$store.dispatch(
                        "DoctorAppointmentStore/GET_LIST_CALL_CENTER_BOOKING"
                    );
                    this.$store.dispatch(
                        "DoctorAppointmentStore/SET_CHANGE_LIST",
                        []
                    );
                    this.$store.dispatch(
                        "DoctorAppointmentStore/SET_CHANGE_LIST",
                        allData
                    );
                    this.$store.dispatch(
                        "DoctorAppointmentStore/SET_SELECTED_PANEL",
                        response.data
                    );
                })
                .catch(() => {
                    toastr.error("Gagal Update Data");
                })
                .finally(() => {
                    // loading off
                    this.isBtnLoad = false;
                });
        },
        // updateNomer() {
        //     // loading on
        //     this.isBtnLoad = true;
        //     var payload = {
        //         nomorRegistrasi: this.noRegis
        //             ? this.noRegis.replaceAll(/\s/g, "")
        //             : "",
        //         nomorRekamMedik: this.noMedical
        //             ? this.noMedical.replaceAll(/\s/g, "")
        //             : ""
        //     };
        //     ReservasiResource.updateNomerRegisDanMed(
        //         this.selectedPanel.id,
        //         payload
        //     )
        //         .then(response => {
        //             toastr.success("Success Update Data");
        //             // toast sukses
        //             const allData = this.$store.state.DoctorAppointmentStore
        //                 .listCallCenterBooking;
        //             const itemSelected = response.data;
        //             var indexChange = allData.findIndex(
        //                 item => item.id == itemSelected.id
        //             );
        //             allData[indexChange] = itemSelected;
        //             this.$store.dispatch(
        //                 "DoctorAppointmentStore/GET_LIST_CALL_CENTER_BOOKING"
        //             );
        //             this.$store.dispatch(
        //                 "DoctorAppointmentStore/SET_CHANGE_LIST",
        //                 []
        //             );
        //             this.$store.dispatch(
        //                 "DoctorAppointmentStore/SET_CHANGE_LIST",
        //                 allData
        //             );
        //             this.$store.dispatch(
        //                 "DoctorAppointmentStore/SET_SELECTED_PANEL",
        //                 response.data
        //             );
        //         })
        //         .catch(() => {
        //             toastr.error("Gagal Update Data");
        //         })
        //         .finally(() => {
        //             // loading off
        //             this.isBtnLoad = false;
        //         });
        // },
        reSchedule() {
            this.$store.commit('DoctorAppointmentStore/SET_SHOW_MODAL_RESERVASI', true)
        },
        cancelOrDismiss(reservasi) {
            const that = this
            new SweetAlert().showConfirm({
                swalTitle: "Apakah Anda yakin?",
                swalText: "Data Akan Dihapus.",
                swalType: "info",
                onConfirmButton: function() {
                    // blockUI.blockModal();
                    that.setLoadingSpinner(true)
                    // if(reservasi.cancelStatus == 'NEED_CONFIRMATION') {
                    if(reservasi.data.status == 'CANCEL') {
                        // post dissmiss
                        that.cancelReservationtoDismiss(reservasi.data.generatedId)
                    } else if (!reservasi.cancelStatus){
                        // post cancel by ids -> dismiss
                        that.cancelReservationbyIds(reservasi.data.generatedId)
                    }
                },
                onCancelButton: function() {
                    // blockUI.unblockModal();
                }
            });
            
        },
        WAme(numberWA, idReservasi) {
            this.$store.commit("DayCalendarStore/SET_SHOW_MODAL_WA", true);
            this.$store.commit("DayCalendarStore/SET_NUMBER_WA", numberWA);
            this.$store.commit("DayCalendarStore/SET_RESERVASI_ID", idReservasi);
        },
        cancelReservationbyIds(generatedId) {
        ReservasiResource.cancelReservationsV3(generatedId)
            .then(() => {
                // this.cancelReservationtoDismiss()
                this.setLoadingSpinner(false)
                new SweetAlert().showInfo({
                    swalTitle: "Berhasil!",
                    swalText: "Berhasil batalkan reservasi",
                    swalType: "success"
                })
            })
            .catch(err => {
                // toastr.error("Gagal cancel reservasi");
                // blockUI.unblockModal();
                 this.setLoadingSpinner(false)
                 new SweetAlert().showInfo({
                    swalTitle: "Gagal!",
                    swalText: "Gagal batalkan reservasi",
                    swalType: "error"
                });
            })
        },
        cancelReservationtoDismiss(generatedId) {
            ReservasiResource.reservasiDismiss(generatedId)
            .then(response => {
            //  toastr.success("Berhasil batalkan reservasi");
            //    console.log(response.data)
            this.setLoadingSpinner(false)
                new SweetAlert().showInfo({
                    swalTitle: "Berhasil!",
                    swalText: "Berhasil batalkan reservasi",
                    swalType: "success"
                })
            })
            .catch(() => {
               this.setLoadingSpinner(false)
                new SweetAlert().showInfo({
                    swalTitle: "Gagal!",
                    swalText: "Gagal batalkan reservasi",
                    swalType: "error"
                });
            })
        }
    },
    watch: {
        "selectedPanel.data.nomorMedicalRecord": function(val) { 
            this.noMedical = val;
        },
        "selectedPanel.data.nomorRegistrasi": function(val) {
            this.noRegis = val;
        },
        "selectedPanel.generatedId": function (val) {
            this.generatedId = val
        }
    },
    computed: {
        ...mapState("DoctorAppointmentStore", {
            selectedPanel: state => state.selectedPanel,
            isLoadedBtn: state => state.isLoadedBtn,
            isPermissionWhatsapp: state => state.isPermissionWhatsapp
        }),
        isBtnLoad: {
            get() {
                return this.$store.state.DoctorAppointmentStore.isLoadedBtn;
            },
            set(val) {
                return this.$store.commit(
                    "DoctorAppointmentStore/SET_IS_LOADED_BTN",
                    val
                );
            }
        }
    }
};
</script>
