<template>
    <div class="kt-portlet kt-portlet--height-fluid kt-portlet--tabs">
    <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                Daftar Reservasi
            </h3>
        </div>
        <div class="kt-portlet__head-toolbar">
            <ul class="nav nav-tabs nav-tabs-line nav-tabs-line-brand nav-tabs-bold" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active show" data-toggle="tab" href="#kt_portlet_tabs_1_1_content" role="tab" aria-selected="false">
                        Hari ini
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#kt_portlet_tabs_1_2_content" role="tab" aria-selected="false">
                        7 hari kedepan
                    </a>
                </li>
            </ul>
            <sound-notifiction ref="notif" :is-visible="false"></sound-notifiction>

        </div>
    </div>
    <div class="kt-portlet__body" style="position: relative;">
        <div class="row mr-4" style="position: absolute; right: 0; z-index:10;">
            <template v-if="isShowRefresh">
                <span class="col-8 my-auto">Auto Refresh </span>
                <div class="col-4">
                    <auto-refresh
                        ref="auth"
                        :table-name="'temp_table'"
                        :on-refresh="onInit"
                        :auto-refresh-state="isAuthRefreshState"
                        :is-visible="false"
                    ></auto-refresh>
                </div>
            </template>
            <template v-else>
                <span class="col-8 my-auto h-100">Refreshing </span>
                <div class="col-4 my-auto h-100">
                   <div class="kt-spinner kt-spinner--v2 kt-spinner--sm kt-spinner--brand"></div>
                </div>
            </template>
        </div>
        <div class="tab-content">
            <div class="tab-pane fade active show" id="kt_portlet_tabs_1_1_content" role="tabpanel">
                <detail-count-reservasi :data="today" v-if="!isLoading" :id="'newScroll'" :start-date="dateToday" :end-date="dateToday"></detail-count-reservasi>
                <div class="text-center" v-else>
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="kt_portlet_tabs_1_2_content" role="tabpanel">
                <detail-count-reservasi :data="data" v-if="!isLoading" :id="'konfrimScroll'" :start-date="dateToday" :end-date="dateNextweek"></detail-count-reservasi>
                <div class="text-center" v-else>
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import DetailCountReservasi from './../_subcomponents/DetailCountReservasi';
import { RepositoryFactory } from "./../../../repositories/RepositoryFactory";
const ReservationRepository = RepositoryFactory.get("reservation");
import AutoRefresh from "./../../_general/AutoRefresh";
import SoundNotifiction from './../../_general/SoundNotification';
import DateRange from './../../../constants/date-range-enum';

export default {
    components: {
        DetailCountReservasi,
        AutoRefresh,
        SoundNotifiction
    },
    data() {
        return {
            data: [],
            today: [],
            isLoading: true,
            dateToday: null,
            dateNextweek: null,
            isAuthRefreshState: false,
            isShowRefresh: false,
            isFirstLoadToday : true,
            maxNotifDateToday: moment(DateRange.MIN_DATE),
            isFirstLoadAll: true,
            maxNotifDateAll: moment(DateRange.MIN_DATE),
        }
    },
    methods: {
        onInit:function() {
            this.isAuthRefreshState = false;
            this.isShowRefresh = false
            this.dateToday = moment().format('YYYY-MM-DD');
            this.dateNextweek = moment().add(7, 'day').format('YYYY-MM-DD');
            var params = {
                "status.in": "CONFIRM,NEW",
                "tanggalKunjungan.greaterOrEqualThan": this.dateToday,
                "tanggalKunjungan.lessOrEqualThan": this.dateNextweek,
                "sort": "createdDate,desc"
            };
            new Promise((resolve, reject) => {
                ReservationRepository.get(params, resolve, reject);
            })
            .then(response => {
                this.data = this.allData(response.data);
                this.today = this.todayData(response.data);
                this.isLoading = false;
                this.isShowRefresh = true;
            }).catch(error => {
                this.isShowRefresh = true;
                var msg = (error.message) ? error.message : error;
                if(msg !== 'close connection') {
                    toastr.error('Reservation : ' + msg);
                } else {
                    toastr.error('Reservation : ' + msg);
                }
            })
        },
        allData: function(response) {
            let call = false;
            var vx = this;
            const data = (!this.isFirstLoadAll) ? response.map(x => {
                x.isNewBO = moment.utc(x.createdDate).isAfter(vx.maxNotifDateAll);
                call = (x.isNewBO) ? true : call;
                return x;
            }) : response;
            this.isFirstLoadAll = false;
            this.maxNotifDateAll = (data.length > 0) ? moment.max(data.map(x => moment.utc(x.createdDate))) : (this.isFirstLoadAll) ? moment(DateRange.MIN_DATE) : this.maxNotifDateToday;
            if(call){
                this.$refs.notif.ring();
            }
            return data;
        },
        todayData: function(response){
            let call = false;
            var vx = this;
            const tempData = response.filter(x => {
                if(moment(x.tanggalKunjungan).isSame(this.dateToday)){
                    return x;
                }
            });

            const data = (!this.isFirstLoadToday) ? tempData.map(x => {
                x.isNewBO = moment.utc(x.createdDate).isAfter(vx.maxNotifDateToday);
                call = (x.isNewBO) ? true : call;
                return x;
            }) : tempData;
            this.isFirstLoadToday = false;
            this.maxNotifDateToday = (data.length > 0) ? moment.max(data.map(x => moment.utc(x.createdDate))) : (this.isFirstLoadToday) ? moment(DateRange.MIN_DATE) : this.maxNotifDateToday;
            if(call){
                this.$refs.notif.ring();
            }
            return data;

        }
    },
    created () {
        this.onInit()
    },
    mounted () {

    },
    beforeDestroy () {
        this.isAuthRefreshState = false
    }

}
</script>

<style>

</style>
